import axios from 'axios'
import camelcaseKeys from 'camelcase-keys'
import { isEmpty } from 'lodash-es'
import { useTranslation } from 'next-i18next'
import useSWR from 'swr'

import UserService from '@/apis/user'
import toast from '@/components/toast'

import CredentialStorage from '@/utils/credential'
import ErrorNotifier, { isNotifiableError } from '@/utils/error-notifier'
import Swal from '@/utils/sweetalert'

const SWR_KEY = 'user_info' as const

type UpdateOptionsType = { hideToast?: boolean }

export default function useSwrMe() {
  const { t } = useTranslation()

  const _fetcher = async () => {
    try {
      if (isEmpty(CredentialStorage.get())) return

      const res = await UserService.get()
      if (res.status === 200) {
        const camelcasedRes = camelcaseKeys(res.data, { deep: true })

        return camelcasedRes
      } else {
        throw new Error('Fetch /me error')
      }
    } catch (err) {
      if (axios.isAxiosError(err)) {
        toast.error({
          message: err?.response?.data?.errors?.[0]?.detail ?? t('err.happens'),
        })
        throw err
      }

      if (isNotifiableError(err)) {
        ErrorNotifier.notify({ err, context: { key: 'Fetch /me error' } })
      }
    }
  }

  const _updater = async (data: any, options?: UpdateOptionsType) => {
    try {
      const res = await UserService.update({ data: { attributes: { ...data } } })

      if (res.status === 200) {
        const info = camelcaseKeys(res.data, { deep: true })
        if (!options?.hideToast) {
          toast.success({ message: t('auth.user_info_update_success') })
        }

        return info
      }
    } catch (err) {
      if (axios.isAxiosError(err)) {
        await Swal.fire({
          icon: 'error',
          title: err?.response?.data?.errors?.[0]?.detail ?? t('err.happens'),
          confirmButtonText: t('common.confirm'),
        })
      }

      if (isNotifiableError(err)) {
        ErrorNotifier.notify({
          err,
          context: {
            key: 'Update User Info Error',
            data: data,
          },
        })
      }
    }
  }

  const { data, mutate, error, isValidating } = useSWR(SWR_KEY, _fetcher, {
    revalidateOnMount: true,
    revalidateIfStale: true,
    revalidateOnFocus: false,
    revalidateOnReconnect: false,
  })

  const update = (data: any, options?: UpdateOptionsType) =>
    mutate(_updater(data, options))

  const loading = isValidating && !error && !data

  return {
    isFetching: loading,
    user: data,
    update,
    mutate,
  }
}
