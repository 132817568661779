import { COOKIE_KEYS as _COOKIE_KEYS } from '@libs-components/utils/cookie-storage'

/**
 * @deprecate Temporary re-export of COOKIE_KEYS from libs-components for capability
 * @description Importing/Adding COOKIE_KEYS directly in libs-components in the future
 **/
export const COOKIE_KEYS = _COOKIE_KEYS

export const WHITELISTED_COOKIE_KEYS = [
  COOKIE_KEYS.SHOP_CART,
  COOKIE_KEYS.TOTAL_CARDS_CREATED,
  COOKIE_KEYS.CREATE_CARD_REMINDER_SHOWN,
  COOKIE_KEYS.SUBSCRIPTION_SHOW_FEEDBACK_MODAL,
]
