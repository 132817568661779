import { useIntercom } from 'react-use-intercom' // import library directly to avoid circular import
import { useTranslation } from 'next-i18next'
import { useRouter } from 'next/router'
import { useEffect } from 'react'

import useSwrMe from '@/data/use-swr-me'
import AuthService from '@/apis/auth'

import { isSchoolAdminAtom, isWuAdminAtom, meAtom } from '@/store/me'
import { utils } from '@/hooks/use-intercom'
import cookieStorage from '@/utils/cookie-storage'
import { COOKIE_KEYS, WHITELISTED_COOKIE_KEYS } from '@/utils/cookie-storage/constants'
import CredentialStorage from '@/utils/credential'
import { useSetAtom } from '@/utils/state-manager'
import { AllPaths } from '@/routes/constants'
import toast from '@/components/toast'

const useAuth = () => {
  const { t } = useTranslation()

  const { user, update, isFetching, mutate } = useSwrMe()
  const setAtomUser = useSetAtom(meAtom)

  const { update: updateIntercom } = useIntercom()
  const router = useRouter()
  const setIsSchoolAdminAtom = useSetAtom(isSchoolAdminAtom)
  const setIsWuAdminAtom = useSetAtom(isWuAdminAtom)

  const logout = async () => {
    try {
      const res = await AuthService.signOut()
      if (res.status === 200) {
        Object.keys(COOKIE_KEYS).forEach(key => {
          const currentCookieKey = COOKIE_KEYS[key as keyof typeof COOKIE_KEYS]
          if (!WHITELISTED_COOKIE_KEYS.includes(currentCookieKey)) {
            cookieStorage.del(currentCookieKey)
            cookieStorage.del(currentCookieKey, {
              domain: process.env.NEXT_PUBLIC_ROOT_DOMAIN!,
            })
          }
        })
        CredentialStorage.clear()
        router.push(`${AllPaths.AUTH}${AllPaths.SIGN_UP}`)
        mutate(undefined)
      }
    } catch (e) {
      toast.error({ message: t('err.happens') })
    }
  }

  const onSignInSuccess = (data: any) => {
    const {
      data: { email, id, name },
    } = data

    const intercomInfo = { name, email, userId: utils.intercomUserId(id) }

    updateIntercom(intercomInfo)
    mutate(data)
  }

  const updateInfo = async (data: any, options?: { hideToast: boolean }) => {
    const info = await update(data, options)
    return info
  }

  const isSchoolAdmin = !!user?.data?.attributes?.schoolAdmin

  const isWuAdmin = !!(user?.data?.attributes?.wuAdminRole === 'basic')

  useEffect(() => {
    setIsSchoolAdminAtom(!!user?.data?.attributes?.schoolAdmin)
    setIsWuAdminAtom(!!(user?.data?.attributes?.wuAdminRole === 'basic'))
    setAtomUser(user)
  }, [setIsSchoolAdminAtom, setIsWuAdminAtom, user])

  return {
    user,
    updateInfo,
    onSignInSuccess,
    logout,
    isSchoolAdmin,
    isWuAdmin,
    isFetching,
  }
}
export default useAuth
